<template>
  <div
    id="contents"
    :class="subTabs[openTabIndex].name + (managementMode ? ' management' : '')"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="openTabIndex"
          :commit="'setOpenTabIndexToCodePage'"
          :options="subTabs"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <a
            @click="ToggleManagementMode()"
            :class="managementMode ? 'btn_admin on' : 'btn_admin'"
            v-if="isPermissionOn"
            href="#"
            >관리</a
          >
          <!-- <button class="btn_admin" :disabled="!managementMode">저장</button> -->
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <!-- 사이드 vertical menu -->
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul v-for="(subTab, index) in subTabs" :key="subTab.name">
          <li
            :class="index == openTabIndex ? 'active' : ''"
            @click="SetOpenTabIndex(index)"
          >
            <a href="#">{{ subTab.title }}</a>
          </li>
        </ul>
      </div>
      <!-- unitForm -->
      <unit-form
        v-if="subTabs[openTabIndex].name == 'unit_management'"
        :managementMode="managementMode"
      ></unit-form>
      <unit-conversion-form
        v-if="subTabs[openTabIndex].name == 'unit_conversion_management'"
        :managementMode="managementMode"
      ></unit-conversion-form>
      <storage-form
        v-if="subTabs[openTabIndex].name == 'warehouse_management'"
      ></storage-form>
      <machine-form
        v-if="subTabs[openTabIndex].name == 'machine_management'"
      ></machine-form>
      <box-form
        v-if="subTabs[openTabIndex].name == 'box_management'"
      ></box-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import UnitForm from '@/views/forms/Code/UnitForm';
import UnitConversionForm from '@/views/forms/Code/UnitConversionForm';
import StorageForm from '@/views/forms/Code/StorageForm';
import MachineForm from '@/views/forms/Code/MachineForm';
import BoxForm from '@/views/forms/Code/BoxForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import AsideSelectric from '@/layouts/components/AsideSelectric.vue';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    UnitForm,
    UnitConversionForm,
    StorageForm,
    MachineForm,
    BoxForm,
    AsideSelectric,
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromCodePage',
      subTabs: 'getSubTabsFromCodePage',
      openTabIndex: 'getOpenTabIndexFromCodePage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToCodePage',
      ToggleManagementMode: 'toggleManagementModeToCodePage',
    }),
  },
  created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitCodePage');
      this.$route.meta.menu_init = false;
    }
    if (this.$route.meta.tab != undefined) {
      this.$store.commit('setOpenTabIndexToCodePage', this.$route.meta.tab);
      delete this.$route.meta.tab;
    }
  },
};
</script>

<style></style>
