<template>
  <div class="article" :class="{ management_mode: managementMode }">
    <div class="tbl_wrap">
      <div class="mes_tbl_wrap">
        <table class="mes_tbl" :class="{ manage: managementMode }">
          <colgroup>
            <col v-for="(n, index) in managementMode ? 4 : 3" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>박스명</th>
              <th>수량</th>
              <th>비고</th>
              <th v-show="managementMode">삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(box, index) in boxs"
              :key="box.id"
              @click="SelectRow(index)"
              :class="selectedIndex == index ? 'active' : ''"
            >
              <td>{{ box.name }}</td>
              <td>{{ $makeComma(box.quantity) }}</td>
              <td>{{ box.detail }}</td>
              <td v-show="managementMode">
                <button @click="ShowModal(index)" class="tbl_delete_btn">
                  delete
                </button>
              </td>
            </tr>
            <tr
              @click="SelectRow(-1)"
              v-show="managementMode"
              :class="selectedIndex == -1 ? 'new' : 'new_disable'"
              class="newBtn"
            >
              <td colspan="4" @click="SelectRow(-1)">
                + 신규 박스 등록
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode" v-show="managementMode">
      <div class="head">
        <h5>{{ selectedIndex == -1 ? '신규 등록' : '내용 수정' }}</h5>
        <!-- <button v-show="selectedIndex == -1" class="excel_btn">
          엑셀로 등록
        </button> -->
      </div>
      <div class="form" @submit.prevent>
        <form>
          <div class="input_text">
            <label>박스명</label>
            <input
              type="text"
              placeholder="NEW"
              v-model="managementData.name"
            />
          </div>
          <div class="input_text">
            <label>수량</label>
            <input
              type="text"
              placeholder="20"
              :value="$makeComma(managementData.quantity)"
              inputmode="decimal"
              @input="$inputNumberInt($event, managementData, 'quantity')"
            />
          </div>
          <div class="input_text">
            <label>소진 포장재</label>
            <div class="mes_tbl_wrap">
              <table class="mes_tbl">
                <colgroup
                  class="use_package"
                  :class="{
                    management_col:
                      managementData.r_box_unit_material_list != undefined &&
                      managementData.r_box_unit_material_list.length > 0,
                  }"
                >
                  <col
                    v-for="(n,
                    index) in managementData.r_box_unit_material_list !=
                      undefined &&
                    managementData.r_box_unit_material_list.length > 0
                      ? 5
                      : 4"
                    :key="index"
                  />
                </colgroup>
                <thead>
                  <tr>
                    <th>포장재명</th>
                    <th>규격</th>
                    <th>단위</th>
                    <th>소모량</th>
                    <th
                      v-show="
                        managementData.r_box_unit_material_list != undefined &&
                          managementData.r_box_unit_material_list.length > 0
                      "
                    >
                      삭제
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item,
                    index) in managementData.r_box_unit_material_list"
                    :key="item.id"
                  >
                    <td class="text_left">
                      {{ findInfoFromId(materials, item.material_id).name }}
                    </td>
                    <td class="text_left">
                      {{ findInfoFromId(materials, item.material_id).standard }}
                    </td>
                    <td>
                      {{
                        findInfoFromId(
                          units,
                          findInfoFromId(materials, item.material_id)
                            .incoming_unit_id,
                        ).name
                      }}
                    </td>
                    <td>
                      <input
                        type="text"
                        :readonly="!managementMode"
                        :value="$makeComma(item.quantity)"
                        inputmode="decimal"
                        @input="
                          $inputNumberIntMinNotZero(
                            $event,
                            item,
                            'quantity',
                            1000,
                            1,
                          )
                        "
                      />
                    </td>
                    <td
                      v-show="
                        managementData.r_box_unit_material_list != undefined &&
                          managementData.r_box_unit_material_list.length > 0
                      "
                    >
                      <button
                        class="tbl_delete_btn"
                        @click="
                          managementData.r_box_unit_material_list.splice(
                            index,
                            1,
                          )
                        "
                      >
                        delete
                      </button>
                    </td>
                  </tr>
                  <tr v-show="managementMode">
                    <td
                      :colspan="
                        managementData.r_box_unit_material_list != undefined &&
                        managementData.r_box_unit_material_list.length > 0
                          ? 5
                          : 4
                      "
                      class="new"
                    >
                      <button
                        class="btn_tbl"
                        @click="selectPackageMaterial"
                        v-show="managementMode"
                      >
                        포장재 선택
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="input_text">
            <label>비고</label>
            <textarea
              placeholder="비고를 작성해주세요."
              v-model="managementData.detail"
            ></textarea>
          </div>
          <button
            class="btn_sub2"
            :disabled="CheckValidate || isValidModify"
            @click="SubmitForm()"
          >
            {{ selectedIndex == -1 ? '등록' : '수정' }}
          </button>
        </form>
      </div>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="delete_box($event)"
    ></two-button-modal>
    <resource-search
      v-if="showPackagingSearch"
      :filter_type="4"
      :ignore_list="ignore_list"
      @onclose="showPackagingSearch = false"
      @onselect="
        $event => {
          managementData.r_box_unit_material_list.push({
            id: $event.id,
            box_unit_id: managementData.id,
            material_id: $event.id,
            resource_type_id: $event.resource_type_id,
            quantity: 1,
          });
          ignore_list = managementData.r_box_unit_material_list;
          showPackagingSearch = false;
        }
      "
    ></resource-search>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';
import FetchMixin from '@/mixins/fetch';

export default {
  components: {
    TwoButtonModal,
    ResourceSearch,
  },
  data() {
    return {
      //modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,

      showPackagingSearch: false,
      ignore_list: [],
    };
  },
  mixins: [ModalMixin, SpinnerMixin, FetchMixin],
  computed: {
    ...mapGetters({
      boxs: 'getBoxs',
      selectedIndex: 'getSelectedIndexFromBox',
      managementData: 'getManagementDataFromBox',
      cloneData: 'getCloneDataFromBox',
      managementMode: 'getManagementModeFromCodePage',
      materials: 'getMaterial',
      units: 'getUnitCodes',
    }),
    isValidModify() {
      if (this.managementMode) {
        let originData = this.cloneData;
        if (isNaN(originData.quantity) == false) {
          originData.quantity = String(originData.quantity);
        }
        originData = JSON.stringify(originData);

        let modifyData = this.lodash.clonedeep(this.managementData);
        if (isNaN(modifyData.quantity) == false) {
          modifyData.quantity = String(modifyData.quantity);
        }
        modifyData = JSON.stringify(modifyData);

        if (modifyData == originData) {
          return true;
        } else return false;
      } else {
        return false;
      }
    },
    CheckValidate() {
      try {
        if (
          this.managementData.name == undefined ||
          this.managementData.name.trim() == '' ||
          this.managementData.quantity == undefined ||
          this.managementData.quantity == '' ||
          this.managementData.quantity == 0 ||
          this.managementData.quantity == '0'
        ) {
          return true;
        } else {
          if (
            this.boxs.find(
              x =>
                x.id != this.managementData.id &&
                x.name == this.managementData.name.trim(),
            )
          ) {
            return true;
          } else {
            return false;
          }
        }
      } catch (error) {
        console.log(error);
        return true;
      }
    },
  },
  methods: {
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    SelectRow(index) {
      this.ignore_list = [];
      this.$store.commit('setSelectedIndexToBox', index);
      if (index == -1) {
        this.$store.commit('setManagementDataToBox', {
          id: -1,
          name: '',
          detail: '',
          quantity: 0,
          r_box_unit_material_list: [],
        });
      } else {
        this.$store.commit(
          'setManagementDataToBox',
          this.lodash.clonedeep(this.boxs[index]),
        );
        this.$store.commit(
          'setCloneDataToBox',
          this.lodash.clonedeep(this.boxs[index]),
        );
      }
    },
    FETCH_BOX() {
      this.showSpinner();
      this.$store
        .dispatch('FETCH_BOX')
        .then(response => {
          console.log(response);
        })
        .catch(() => {
          this.openOneButtonModal(
            '로드 중 오류',
            '박스 정보를 불러오는 중 오류 발생.',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async SubmitForm() {
      this.showSpinner();
      if (this.selectedIndex == -1) {
        //신규 박스
        let manageData = this.lodash.clonedeep(this.managementData);
        manageData.name = manageData.name.trim();
        manageData.quantity = this.$makeNumber(manageData.quantity);
        manageData.r_box_unit_material_list.map(x => {
          x.quantity = this.$makeNumber(x.quantity);
        });

        this.$store
          .dispatch('INSERT_BOX', {
            data_list: [manageData],
          })
          .then(response => {
            manageData.id = response.data.data.id;
            manageData.r_box_unit_material_list.map(x => {
              x.box_unit_id = manageData.id;
            });

            this.$store
              .dispatch('INSERT_PACKAGE_BOX', {
                id: manageData.id,
                r_box_unit_material_list: manageData.r_box_unit_material_list,
              })
              .then(() => {
                this.FETCH_BOX();
              })
              .catch(() => {
                this.openOneButtonModal(
                  '등록 오류',
                  '등록 중 오류가 발생했습니다.',
                );
              });
          })
          .catch(() => {
            this.openOneButtonModal(
              '등록 오류',
              '등록 중 오류가 발생했습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else {
        //박스 수정
        let manageData = this.lodash.clonedeep(this.managementData);
        manageData.name = manageData.name.trim();
        manageData.quantity = this.$makeNumber(manageData.quantity);
        manageData.r_box_unit_material_list.map(x => {
          x.quantity = this.$makeNumber(x.quantity);
        });

        this.$store
          .dispatch('UPDATE_BOX', {
            data_list: [manageData],
          })
          .then(() => {
            this.$store
              .dispatch('INSERT_PACKAGE_BOX', {
                id: manageData.id,
                r_box_unit_material_list: manageData.r_box_unit_material_list,
              })
              .then(() => {
                this.$store.commit('setCloneDataToBox', {
                  id: -1,
                  name: '',
                  detail: '',
                  quantity: 0,
                  r_box_unit_material_list: [],
                });
                this.FETCH_BOX();
              })
              .catch(() => {
                this.openOneButtonModal(
                  '수정 오류',
                  '수정 중 오류가 발생했습니다.',
                );
              });
          })
          .catch(() => {
            this.openOneButtonModal(
              '수정 오류',
              '수정 중 오류가 발생했습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
      this.SelectRow(-1);
    },
    async delete_box(arg1) {
      console.log(arg1);
      if (arg1 == undefined) return;
      this.showSpinner();
      //박스 삭제
      this.$store
        .dispatch('DELETE_BOX', {
          data_list: [{ id: this.managementData.id }],
        })
        .then(() => {
          this.FETCH_BOX();
          this.SelectRow(-1);
        })
        .catch(() => {
          this.openOneButtonModal('삭제 오류', '삭제 중 오류 발생.');
        })
        .finally(() => {
          this.hideSpinner();
          this.CloseModal();
        });

      this.hideSpinner();
    },
    selectPackageMaterial() {
      let packageList = this.lodash.clonedeep(
        this.managementData.r_box_unit_material_list,
      );
      packageList.map(x => {
        x.id = x.material_id;
        x.resource_type_id = this.findInfoFromId(
          this.materials,
          x.material_id,
        ).resource_type_id;
      });

      this.ignore_list = packageList;
      this.showPackagingSearch = true;
    },
  },
  async created() {
    if (this.boxs.length == 0) {
      await this.FETCH_BOX();
    }
    if (this.units.length == 0) {
      await this.FETCH('FETCH_UNIT');
    }
    if (this.materials.length == 0) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY');
    }
  },
};
</script>

<style scoped></style>
