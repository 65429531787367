<template>
  <div class="article">
    <!-- <div class="tbl_wrap" :id="managementMode ? ' managementMode-small' : ''"> -->
    <div class="tbl_wrap">
      <div class="mes_tbl_wrap">
        <table class="mes_tbl" :class="{ manage: managementMode }">
          <colgroup>
            <col v-for="(n, index) in managementMode ? 4 : 3" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>No</th>
              <th>단위명</th>
              <th>설명</th>
              <th v-if="managementMode">삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(unitCode, index) in unitCodes"
              :key="(unitCode.id, unitCode.name)"
              @click="SelectRow(index)"
              @focus="SelectRow(index)"
              :class="selectedIndex == index ? 'active' : ''"
              tabindex="0"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ unitCode.name }}</td>
              <td>{{ unitCode.detail }}</td>
              <td v-if="managementMode">
                <button class="tbl_delete_btn" @click="ShowModal(index)">
                  delete
                </button>
              </td>
            </tr>
            <tr
              v-show="managementMode"
              :class="selectedIndex == -1 ? 'new' : 'new_disable'"
              class="newBtn"
              @click="SelectRow(-1)"
              tabindex="0"
              @focus="SelectRow(-1)"
            >
              <td colspan="4">
                + 신규 단위 등록
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode" v-show="managementMode">
      <div class="head">
        <h5>{{ selectedIndex != -1 ? '내용 변경' : '신규 등록' }}</h5>
        <!-- <button v-show="selectedIndex == -1" class="excel_btn">
          엑셀로 등록
        </button> -->
      </div>
      <div class="form" @submit.prevent="submitForm">
        <form>
          <div class="input_text">
            <label>단위</label>
            <input
              type="text"
              placeholder="명칭 입력"
              v-model="managementData.name"
            />
          </div>
          <div class="input_text">
            <label>설명</label>
            <input
              type="text"
              placeholder="설명 입력"
              v-model="managementData.detail"
            />
          </div>
          <button class="btn_sub2" :disabled="checkValid || isValidModify">
            {{ selectedIndex != -1 ? '수정' : '등록' }}
          </button>
        </form>
      </div>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="delete_unit($event)"
    ></two-button-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';

export default {
  components: {
    TwoButtonModal,
  },
  data() {
    return {
      //modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  props: {
    managementMode: Boolean,
  },
  mixins: [ModalMixin, SpinnerMixin],
  computed: {
    ...mapGetters({
      unitCodes: 'getUnitCodes',
      selectedIndex: 'getSelectedIndexFromUnit',
      managementData: 'getManagementDataFromUnit',
    }),
    isValidModify() {
      if (this.managementMode) {
        let originData = this.lodash.clonedeep(
          this.unitCodes[this.selectedIndex],
        );
        let modifyData = this.lodash.clonedeep(this.managementData);

        if (this.selectedIndex != -1) {
          if (originData.detail == null && modifyData.detail == '') {
            modifyData.detail = null;
          }
        }
        originData = JSON.stringify(originData);
        modifyData = JSON.stringify(modifyData);

        if (modifyData == originData) {
          return true;
        } else return false;
      } else return false;
    },
    checkValid() {
      if (
        this.managementData.name != undefined &&
        this.managementData.name != ''
      ) {
        return false;
      } else return true;
    },
    validName() {
      const unitCodeListChk = this.lodash
        .clonedeep(this.unitCodes)
        .find(
          x =>
            x.id != this.managementData.id &&
            x.name == this.managementData.name,
        );

      if (unitCodeListChk != undefined) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapMutations([
      'setSelectedIndexToUnit',
      'setManagementDataToUnit',
      'removeUnitCode',
      'updateUnitCode',
      'insertUnitCode',
    ]),
    SelectRow(index) {
      //행 선택 메소드
      let manageData = {};
      if (index == -1 || this.unitCodes.length == 0) {
        manageData = {
          id: -1,
          name: '',
          detail: '',
        };
        this.setSelectedIndexToUnit(-1);
      } else {
        manageData = {
          id: this.unitCodes[index].id,
          name: this.unitCodes[index].name,
          detail: this.unitCodes[index].detail,
        };
        this.setSelectedIndexToUnit(index);
      }
      this.setManagementDataToUnit(manageData);
    },
    async FETCH_UNIT() {
      this.showSpinner();
      this.$store
        .dispatch('FETCH_UNIT')
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal('로드 중 에러', '단위 로드 중 에러발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async delete_unit(arg1) {
      if (arg1 == undefined) return;
      console.log('arg1', arg1);
      this.CloseModal();
      if (this.unitCodes[arg1] == null || undefined) {
        return;
      }

      this.showSpinner();
      this.$store
        .dispatch('DELETE_UNIT', this.unitCodes[arg1].id)
        .then(() => {
          this.FETCH_UNIT();
          this.managementData.name = '';
          this.managementData.detail = '';
          this.managementData.id = -1;
          // this.FETCH_UNIT();
          this.setSelectedIndexToUnit(-1);
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '삭제 오류',
            '단위 삭제 중 오류가 발생하였습니다. 다시 로그인하여주십시오.',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    async submitForm() {
      if (this.validName) {
        this.openOneButtonModal(
          '등록 불가',
          `같은 이름의 단위명이 등록이 되어있습니다.<br />다른 단위명을 등록해주세요.`,
        );
        return;
      }

      //폼 제출 메소드
      if (this.managementData.id == -1 && this.selectedIndex == -1) {
        //신규 행 등록
        console.log('new');
        this.showSpinner();
        this.$store
          .dispatch('INSERT_UNIT', {
            data_list: [
              {
                name: this.managementData.name,
                detail: this.managementData.detail,
              },
            ],
          })
          .then(() => {
            this.managementData.name = '';
            this.managementData.detail = '';
            this.managementData.id = -1;
            this.FETCH_UNIT();
          })
          .catch(() => {
            this.openOneButtonModal('등록 중 에러', '단위 등록 중 에러발생');
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else {
        //기존 행 수정
        this.$store
          .dispatch('UPDATE_UNIT', { data_list: [this.managementData] })
          .then(() => {
            this.managementData.name = '';
            this.managementData.detail = '';
            this.managementData.id = -1;

            console.log('update success');
            this.FETCH_UNIT();
          })
          .catch(() => {
            this.openOneButtonModal('수정 중 에러', '단위 등록 중 에러발생');
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
      this.setSelectedIndexToUnit(-1);
    },
  },
  created() {
    if (this.unitCodes.length == 0) {
      console.log('fetchUnitCode');
      this.FETCH_UNIT();
    }
  },
};
</script>

<style scoped></style>
