<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="mes_tbl_wrap">
        <table class="mes_tbl" :class="{ manage: managementMode }">
          <colgroup>
            <col v-for="(n, index) in managementMode ? 6 : 5" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>No</th>
              <th>설비기기명</th>
              <th>설비기기 위치</th>
              <th>공정검사</th>
              <th>비고</th>
              <th v-show="managementMode">삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(machine, index) in filterMachineSort"
              :key="machine.id"
              @click="SelectRow(index)"
              :class="selectedIndex == index ? 'active' : ''"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ machine.name }}</td>
              <td>{{ machine.machine_location }}</td>
              <td>
                {{
                  findInfoFromId(verifications, machine.process_verification_id)
                    .inspection_equipment
                }}
              </td>
              <td>{{ machine.detail }}</td>
              <td v-show="managementMode">
                <button @click="ShowModal(index)" class="tbl_delete_btn">
                  delete
                </button>
              </td>
            </tr>
            <tr
              @click="SelectRow(-1)"
              v-show="managementMode"
              :class="selectedIndex == -1 ? 'new' : 'new_disable'"
              class="newBtn"
            >
              <td colspan="6" @click="SelectRow(-1)">
                + 신규 설비 등록
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode" v-show="managementMode">
      <div class="head">
        <h5>{{ selectedIndex == -1 ? '신규 등록' : '내용 수정' }}</h5>
        <!-- <button v-show="selectedIndex == -1" class="excel_btn">
          엑셀로 등록
        </button> -->
      </div>
      <div class="form" @submit.prevent>
        <form>
          <div class="input_text">
            <label
              class="require"
              :class="{
                base_info: CheckValidate.includes('설비기기명') && baseInfo,
              }"
              >설비기기명</label
            >
            <input
              type="text"
              placeholder="설비 명칭"
              v-model="managementData.name"
            />
          </div>
          <div class="input_text">
            <label
              class="require"
              :class="{
                base_info: CheckValidate.includes('설비기기 위치') && baseInfo,
              }"
              >설비기기 위치</label
            >
            <input
              type="text"
              placeholder="설비위치 명칭"
              v-model="managementData.machine_location"
            />
          </div>
          <div class="input_text">
            <label>비고</label>
            <input
              type="text"
              placeholder="비고 입력"
              v-model="managementData.detail"
            />
          </div>
          <div class="input_text">
            <label>공정검사</label>
            <my-local-selectric
              v-if="showSelectric"
              :id="'verificationSelect'"
              :options="processVerificationOptions"
              :watch="managementData.process_verification_id"
              @changeValue="
                $event => {
                  if ($event == 'new') {
                    showModal = true;
                  } else {
                    managementData.process_verification_id = $event;
                  }
                }
              "
            >
            </my-local-selectric>
          </div>
          <div class="input_text counter">
            <label>계수기 연동</label>
            <button class="check_btn" @click="showCounterPlcModal = true">
              연동
            </button>
            <div v-show="managementData.counter_value.length > 0">
              <p v-for="(pc, index) in pass_counter" :key="index">
                {{
                  '통과 : ' + findInfoFromId(plc_sensors, pc.counter_id).name
                }}
              </p>
              <p
                class="poor_txt"
                v-for="(fc, index) in fail_counter"
                :key="index"
              >
                {{
                  '불량 : ' + findInfoFromId(plc_sensors, fc.counter_id).name
                }}
              </p>
            </div>
          </div>

          <button
            class="btn_sub2"
            :disabled="isValidModify"
            @click="SubmitForm()"
          >
            {{ selectedIndex == -1 ? '등록' : '수정' }}
          </button>
        </form>
      </div>
    </div>
    <new-verification
      v-if="showModal"
      :verification_type_id="2"
      @onclose="showModal = false"
    ></new-verification>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="delete_machine($event)"
    ></two-button-modal>
    <setCounterPlcModal
      v-if="showCounterPlcModal"
      :managementData="managementData"
      @onclose="showCounterPlcModal = false"
      @onSubmit="
        $event => {
          managementData.counter_value = $event;
          showCounterPlcModal = false;
        }
      "
    ></setCounterPlcModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import FETCH_MIXIN from '@/mixins/fetch';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import NewVerification from '@/layouts/components/NewVerification.vue';
import SetCounterPlcModal from '@/layouts/components/setCounterPlcModal.vue';

export default {
  components: {
    TwoButtonModal,
    MyLocalSelectric,
    NewVerification,
    SetCounterPlcModal,
  },
  data() {
    return {
      //selectric
      showSelectric: false,
      //modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,

      //new seqVerification modal
      showModal: false,
      baseInfo: false,
      //set Counter plc modal
      showCounterPlcModal: false,
    };
  },
  mixins: [ModalMixin, SpinnerMixin, FETCH_MIXIN],
  computed: {
    ...mapGetters({
      machines: 'getMachines',
      selectedIndex: 'getSelectedIndexFromMachine',
      managementData: 'getManagementDataFromMachine',
      managementMode: 'getManagementModeFromCodePage',
      processVerificationOptions:
        'getSeqVerificationForSelectricFromQualityManagement',
      verifications: 'getBaseVerificationFromQualityManagementNotSort',
      plc_sensors: 'getPlcSensor',
    }),
    filterMachineSort() {
      return this.lodash.clonedeep(this.machines).sort((a, b) => a.id - b.id);
    },
    pass_counter() {
      return this.lodash
        .clonedeep(this.managementData.counter_value)
        .filter(x => !x.poor_counter_yn);
    },
    fail_counter() {
      return this.lodash
        .clonedeep(this.managementData.counter_value)
        .filter(x => x.poor_counter_yn);
    },
    isValidModify() {
      if (this.managementMode) {
        let originData = this.lodash.clonedeep(
          this.machines[this.selectedIndex],
        );
        let modifyData = this.lodash.clonedeep(this.managementData);

        if (this.selectedIndex != -1) {
          if (originData.detail == null && modifyData.detail == '') {
            modifyData.detail = null;
          }
        }
        originData = JSON.stringify(originData);
        modifyData = JSON.stringify(modifyData);

        if (modifyData == originData) {
          return true;
        } else return false;
      } else return false;
    },
    CheckValidate() {
      let chk = [];
      if (
        this.managementData.name == undefined ||
        this.managementData.name == ''
      ) {
        chk.push('설비기기명');
      }
      if (
        this.managementData.machine_location == undefined ||
        this.managementData.machine_location == ''
      ) {
        chk.push('설비기기 위치');
      }
      return chk;
      // try {
      //   if (
      //     this.managementData.name == undefined ||
      //     this.managementData.name == ''
      //   ) {
      //     return true;
      //   }
      //   return false;
      // } catch (error) {
      //   console.log(error);
      //   return true;
      // }
    },
  },
  methods: {
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    SelectRow(index) {
      this.$store.commit('setSelectedIndexToMachine', index);
      if (index == -1) {
        this.$store.commit('setManagementDataToMachine', {
          id: -1,
          name: '',
          detail: '',
          machine_location: '',
          process_verification_id: '',
          counter_value: [],
        });
      } else {
        this.$store.commit(
          'setManagementDataToMachine',
          this.lodash.clonedeep(this.filterMachineSort[index]),
        );
      }
    },
    FETCH_MACHINE() {
      this.showSpinner();
      this.$store
        .dispatch('FETCH_MACHINE')
        .then(response => {
          console.log(response);
        })
        .catch(() => {
          this.openOneButtonModal(
            '로드 중 오류',
            '설비 정보를 불러오는 중 오류 발생.',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async SubmitForm() {
      if (this.CheckValidate.length > 0) {
        this.openOneButtonModal(
          '내용 입력',
          `${this.CheckValidate.join()}
          은(는) 반드시 입력하여 주십시오.`,
        );
        this.baseInfo = true;
        return;
      }
      const payload = this.lodash.clonedeep(this.managementData);
      if (payload.process_verification_id == '') {
        payload.process_verification_id = null;
      }
      this.showSpinner();

      if (this.selectedIndex == -1) {
        //신규 설비
        this.$store
          .dispatch('INSERT_MACHINE', payload)
          .then(response => {
            console.log(response);
            this.FETCH_MACHINE();
          })
          .catch(error => {
            this.openOneButtonModal(
              '등록 오류',
              '등록 중 오류가 발생했습니다.',
            );
            console.log(error);
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else {
        //설비 수정
        this.$store
          .dispatch(
            'UPDATE_MACHINE',
            this.lodash.clonedeep(this.managementData),
          )
          .then(() => {
            this.FETCH_MACHINE();
            this.baseInfo = false;
          })
          .catch(() => {
            this.openOneButtonModal(
              '수정 오류',
              '수정 중 오류가 발생했습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
      this.SelectRow(-1);
    },
    async delete_machine(arg1) {
      console.log(arg1);
      if (arg1 == undefined) return;
      this.showSpinner();
      //설비 삭제
      this.$store
        .dispatch('DELETE_MACHINE', this.managementData.id)
        .then(() => {
          this.FETCH_MACHINE();
          this.SelectRow(-1);
        })
        .catch(() => {
          this.openOneButtonModal('삭제 오류', '삭제 중 오류 발생.');
        })
        .finally(() => {
          this.hideSpinner();
          this.CloseModal();
        });

      this.hideSpinner();
    },
  },
  async created() {
    if (this.machines.length == 0) {
      await this.FETCH_MACHINE();
    }
    if (this.plc_sensors.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR', 'PLC 센서');
    }
    if (this.verifications.length < 1) {
      await this.FETCH_NO_SPINNER('FETCH_BASE_VERIFICATION', '공정검사');
    }
    this.showSelectric = true;
  },
};
</script>

<style scoped></style>
