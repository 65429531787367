<template>
  <div class="article">
    <div class="warehouse tbl_wrap">
      <div class="depth1" v-for="(store, index) in stores" :key="store.id">
        <div
          class="info"
          :style="
            selectedIndex.depth == 1 && selectedIndex.index == index
              ? 'background-color: #e4edfc;'
              : ''
          "
          @click="SelectItem({ depth: 1, index: index })"
        >
          <span class="name">{{ store.name }}</span>
          <button
            class="ico_more active"
            v-if="managementMode"
            @click="ShowModal(index)"
          ></button>
        </div>
        <transition
          ><span
            class="tooltip"
            :class="{ active: store.detail != undefined }"
            >{{ store.detail }}</span
          ></transition
        >
        <div class="depth2" v-if="store.locations.length > 0 || managementMode">
          <div class="depth2_list">
            <div
              class="list_item"
              v-for="(location, index2) in store.locations"
              :key="location.id"
              @click="SelectItem({ depth: 2, index: index, subIndex: index2 })"
            >
              <span
                class="name"
                :style="
                  selectedIndex.depth == 2 &&
                  selectedIndex.index == index &&
                  selectedIndex.subIndex == index2
                    ? 'color : #6c9bd2'
                    : ''
                "
                >{{ location.name }}</span
              >
              <span class="tooltip" v-show="location.detail !== ''">{{
                location.detail
              }}</span>
              <button
                @click="ShowModal(index)"
                class="ico_more active"
                v-if="managementMode"
              >
                "tooltip"
              </button>
            </div>
            <div
              class="list_item"
              v-if="managementMode"
              @click="SelectItem({ depth: 2, index: index, subIndex: -1 })"
            >
              <span
                class="name"
                :style="
                  selectedIndex.depth == 2 &&
                  selectedIndex.index == index &&
                  selectedIndex.subIndex == -1
                    ? 'color: #6c9bd2;'
                    : 'color : #bbb;'
                "
                >신규 위치</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="depth1" v-if="managementMode">
        <div
          class="info"
          :style="
            selectedIndex.depth == 1 && selectedIndex.index == -1
              ? 'background-color: #f0f7fc;'
              : 'background-color: #efefef;'
          "
          @click="SelectItem({ depth: 1, index: -1 })"
        >
          <span
            class="name"
            :style="
              selectedIndex.depth == 1 && selectedIndex.index == -1
                ? 'color: #6c9bd2;'
                : 'color : #bbb;'
            "
            >신규 창고</span
          >
        </div>
      </div>
    </div>
    <div class="aside_management_mode" v-if="managementMode" @submit.prevent>
      <div class="head">
        <h5>
          {{
            (selectedIndex.depth == 1 && selectedIndex.index == -1) ||
            (selectedIndex.depth == 2 && selectedIndex.subIndex == -1)
              ? '신규 등록'
              : '내용 수정'
          }}
        </h5>
      </div>
      <div class="form">
        <form>
          <div class="input_text">
            <label>{{ selectedIndex.depth == 1 ? '창고' : '위치' }}</label>
            <input
              type="text"
              placeholder="NEW"
              v-model="managementData.name"
            />
          </div>
          <div class="input_text">
            <label>설명</label>
            <input
              type="text"
              :placeholder="
                selectedIndex.depth == 1 ? '창고 설명' : '위치 설명'
              "
              v-model="managementData.detail"
            />
          </div>
          <button
            class="btn_sub2"
            :disabled="checkValidData || isValidModify"
            @click="SubmitForm"
          >
            {{
              (selectedIndex.depth == 1 && selectedIndex.index == -1) ||
              (selectedIndex.depth == 2 && selectedIndex.subIndex == -1)
                ? '등록'
                : '수정'
            }}
          </button>
        </form>
      </div>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="delete_store($event)"
    ></two-button-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';

export default {
  components: {
    TwoButtonModal,
  },
  data() {
    return {
      //modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  mixins: [SpinnerMixin, ModalMixin],
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromCodePage',
      stores: 'getStores',
      selectedIndex: 'getSelectedIndexFromStore',
      managementData: 'getManagementDataFromStore',
    }),
    isValidModify() {
      if (this.managementMode && this.managementData.id != -1) {
        let originData =
          this.selectedIndex.depth == 1
            ? this.lodash.clonedeep(this.stores[this.selectedIndex.index])
            : this.lodash.clonedeep(
                this.stores[this.selectedIndex.index].locations[
                  this.selectedIndex.subIndex
                ],
              );
        let modifyData = this.lodash.clonedeep(this.managementData);

        if (originData.detail == null && modifyData.detail == '') {
          modifyData.detail = null;
        }
        originData = JSON.stringify(originData);
        modifyData = JSON.stringify(modifyData);

        if (modifyData == originData) {
          return true;
        } else return false;
      } else return false;
    },
    checkValidData() {
      if (
        this.managementData.name != undefined &&
        this.managementData.name != ''
      ) {
        return false;
      } else return true;
    },
    chkSameStore() {
      const storeNameList =
        this.selectedIndex.depth == 1
          ? this.lodash.clonedeep(this.stores)
          : this.lodash.clonedeep(
              this.stores[this.selectedIndex.index].locations,
            );

      const findSameStore = storeNameList.find(
        x =>
          x.id != this.managementData.id &&
          x.name == this.managementData.name.trim(),
      );

      if (findSameStore != undefined) {
        return true;
      } else return false;
    },
  },
  methods: {
    ...mapMutations({
      setSelectedIndex: 'setSelectedIndexToStore',
      setManagementData: 'setManagementDataToStore',
    }),
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    FETCH_STORE() {
      this.showSpinner();
      this.$store
        .dispatch('FETCH_STORE')
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '로드 중 에러',
            '창고 리스트 로드 중 에러발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    SelectItem(indexInfo) {
      this.setSelectedIndex(indexInfo);
      if (indexInfo.depth == 1) {
        if (indexInfo.index == -1) {
          this.setManagementData({
            id: -1,
            name: '',
            detail: '',
          });
        } else {
          this.setManagementData(
            this.lodash.clonedeep(this.stores[indexInfo.index]),
          );
        }
      } else if (indexInfo.depth == 2) {
        if (indexInfo.subIndex == -1) {
          this.setManagementData({
            id: -1,
            name: '',
            detail: '',
            store_id: this.stores[indexInfo.index].id,
          });
        } else {
          this.setManagementData(
            this.lodash.clonedeep(
              this.stores[indexInfo.index].locations[indexInfo.subIndex],
            ),
          );
        }
      }
    },
    async SubmitForm() {
      if (!this.CheckValid()) {
        this.openOneButtonModal('내용 입력', '명칭을 반드시 입력하여주십시오.');
        return;
      }
      if (this.chkSameStore) {
        const depthChk = this.selectedIndex.depth == 1 ? '창고' : '위치';
        this.openOneButtonModal(
          '동일 ' + depthChk + ' 존재',
          '이미 동일한 ' + depthChk + '(이)가 등록이 되어있습니다.',
        );
        return;
      }

      this.showSpinner();
      if (this.selectedIndex.depth == 1) {
        //창고
        if (this.managementData.id == -1) {
          //신규 창고
          await this.$store
            .dispatch('INSERT_STORE', this.managementData)
            .then(() => {
              this.FETCH_STORE();
            })
            .catch(() => {
              this.openOneButtonModal('등록 오류', '등록 중 오류 발생.');
            });
        } else {
          //창고 수정
          await this.$store
            .dispatch('UPDATE_STORE', this.managementData)
            .then(() => {
              this.FETCH_STORE();
            })
            .catch(() => {
              this.openOneButtonModal('수정 오류', '수정 중 오류 발생.');
            });
          console.log('창고 수정', this.managementData);
        }
      } else if (this.selectedIndex.depth == 2) {
        //위치
        if (this.managementData.id == -1) {
          //신규 위치
          await this.$store
            .dispatch('INSERT_STORE_LOCATION', this.managementData)
            .then(() => {
              this.FETCH_STORE();
              this.SelectItem({
                depth: 2,
                index: this.selectedIndex.index,
                subIndex: -1,
              });
            })
            .catch(() => {
              this.openOneButtonModal('등록 오류', '등록 중 오류 발생.');
            });
        } else {
          //위치 수정
          await this.$store
            .dispatch('UPDATE_STORE_LOCATION', this.managementData)
            .then(() => {
              this.FETCH_STORE();
            })
            .catch(() => {
              this.openOneButtonModal('수정 오류', '수정 중 오류 발생.');
            });
          console.log('위치 수정', this.managementData);
        }
      }
      this.SelectItem({ depth: 1, index: -1 });
      this.hideSpinner();
    },
    async delete_store(arg1) {
      if (arg1 == undefined) return;
      this.showSpinner();
      if (this.selectedIndex.depth == 1) {
        //창고 삭제
        this.$store
          .dispatch('DELETE_STORE', this.managementData.id)
          .then(() => {
            this.FETCH_STORE();
            this.SelectItem({ depth: 1, index: -1 });
          })
          .catch(() => {
            this.openOneButtonModal('삭제 오류', '삭제 중 오류 발생.');
          })
          .finally(() => {
            this.hideSpinner();
            this.CloseModal();
          });
      } else if (this.selectedIndex.depth == 2) {
        //위치 삭제
        this.$store
          .dispatch('DELETE_STORE_LOCATION', this.managementData.id)
          .then(() => {
            this.FETCH_STORE();
            this.SelectItem({
              depth: 2,
              index: this.selectedIndex.index,
              subIndex: -1,
            });
          })
          .catch(() => {
            this.openOneButtonModal('삭제 오류', '삭제 중 오류 발생.');
          })
          .finally(() => {
            this.hideSpinner();
            this.CloseModal();
          });
      }
      this.hideSpinner();
    },
    CheckValid() {
      if (
        this.managementData.name == undefined ||
        this.managementData.name == ''
      ) {
        return false;
      } else {
        return true;
      }
    },
  },

  async created() {
    if (this.stores.length == 0) {
      this.FETCH_STORE();
    }
  },
};
</script>

<style lang="scss" scoped></style>
