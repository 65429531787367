<template>
  <div class="article">
    <!-- <div class="tbl_wrap" :id="managementMode ? ' managementMode-small' : ''"> -->
    <div class="tbl_wrap">
      <div class="mes_tbl_wrap">
        <table class="mes_tbl" :class="{ manage: managementMode }">
          <colgroup>
            <col v-for="(n, index) in managementMode ? 4 : 3" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>입고단위</th>
              <th>출고단위</th>
              <th>환산비율</th>
              <th v-if="managementMode">삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in unit_conversions"
              :key="item.id"
              @click="selectRow(index)"
              :class="selectedIndex == index ? 'active' : ''"
            >
              <td>{{ findInfoFromId(units, item.in_unit_id).name }}</td>
              <td>{{ findInfoFromId(units, item.out_unit_id).name }}</td>
              <td>{{ item.cvr_ratio | makeComma }}</td>
              <td v-if="managementMode">
                <button class="tbl_delete_btn" @click="showModal = true">
                  delete
                </button>
              </td>
            </tr>
            <tr
              v-show="managementMode"
              :class="selectedIndex == -1 ? 'new' : 'new_disable'"
              class="newBtn"
            >
              <td colspan="4" @click="selectRow(-1)">
                + 신규 단위 등록
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="aside_management_mode"
      v-if="managementMode && managementData != null"
    >
      <div class="head">
        <h5>{{ selectedIndex != -1 ? '내용 변경' : '신규 등록' }}</h5>
      </div>
      <div class="form" @submit.prevent>
        <form>
          <div class="input_text">
            <label>입고단위</label>
            <my-local-selectric
              :id="'inUnitSelectric'"
              :watch="managementData.in_unit_id"
              :options="unit_options"
              @changeValue="
                $event => {
                  managementData.in_unit_id = $event;
                }
              "
            >
            </my-local-selectric>
          </div>
          <div class="input_text">
            <label>출고단위</label>
            <my-local-selectric
              :id="'outUnitSelectric'"
              :watch="managementData.out_unit_id"
              :options="unit_options"
              @changeValue="
                $event => {
                  managementData.out_unit_id = $event;
                }
              "
            >
            </my-local-selectric>
          </div>
          <div class="input_text">
            <label>환산비율</label>
            <input
              type="text"
              placeholder="0.0001"
              :value="$makeComma(managementData.cvr_ratio)"
              inputmode="decimal"
              @input="$inputNumber($event, managementData, 'cvr_ratio')"
            />
          </div>
          <button
            class="btn_sub2"
            @click="submitForm()"
            :disabled="!checkValid || isValidModify"
          >
            {{ selectedIndex != -1 ? '수정' : '등록' }}
          </button>
        </form>
      </div>
    </div>
    <two-button-modal
      :modal_title="'삭제경고'"
      :modal_content="'정말로 삭제하시겠습니까?'"
      :isModalOpen="showModal"
      @onclose="showModal = false"
      @OnYesClick="
        $event => {
          delete_unit_conversion();
          showModal = false;
        }
      "
    ></two-button-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import fetchMixin from '@/mixins/fetch';
import modalMixin from '@/mixins/modal';
import spinnerMixin from '@/mixins/spinner';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
export default {
  mixins: [fetchMixin, modalMixin, spinnerMixin],
  components: {
    TwoButtonModal,
    MyLocalSelectric,
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromCodePage',
      unit_conversions: 'getUnitConversion',
      selectedIndex: 'getSelectedIndexFromUnitConversion',
      managementData: 'getManagementDataFromUnitConversion',
      units: 'getUnitCodes',
      unit_options: 'getUnitForSelectric',
    }),
    checkValid() {
      if (this.managementData == null) {
        return false;
      } else {
        if (
          this.managementData.in_unit_id == null ||
          this.managementData.out_unit_id == null
        ) {
          return false;
        }
        if (this.managementData.cvr_ratio <= 0) {
          return false;
        }
        let listTemp = null;
        if (this.managementData.id == undefined) {
          listTemp = this.lodash.clonedeep(this.unit_conversions);
        } else {
          listTemp = this.lodash.clonedeep(
            this.unit_conversions.filter(
              (x, index) => index != this.selectedIndex,
            ),
          );
        }
        const find = listTemp.find(
          x =>
            x.in_unit_id == this.managementData.in_unit_id &&
            x.out_unit_id == this.managementData.out_unit_id,
        );
        if (find != undefined) {
          return false;
        } else {
          return true;
        }
      }
    },
    isValidModify() {
      if (this.managementMode) {
        const originData = JSON.stringify(
          this.lodash.clonedeep(this.unit_conversions[this.selectedIndex]),
        );
        let modifyData = this.lodash.clonedeep(this.managementData);

        modifyData.cvr_ratio = this.$makeNumber(modifyData.cvr_ratio);
        modifyData = JSON.stringify(modifyData);

        if (modifyData == originData) {
          return true;
        } else return false;
      } else return false;
    },
  },
  methods: {
    async submitForm() {
      if (this.managementData != null) {
        const payload = this.lodash.clonedeep(this.managementData);
        payload.cvr_ratio = this.$makeNumber(payload.cvr_ratio);
        if (this.managementData.id != undefined) {
          //수정
          const result = await this.UPDATE_WITH_PAYLOAD(
            'UPDATE_UNIT_CONVERSION',
            payload,
            '단위환산식',
          );
          console.log(result);
          if (result != null) {
            await this.FETCH('FETCH_UNIT_CONVERSION', '단위환산');
            this.selectRow(-1);
          }
        } else {
          //등록
          console.log(result);
          const result = await this.INSERT_WITH_PAYLOAD(
            'INSERT_UNIT_CONVERSION',
            payload,
            '단위환산식',
          );
          if (result != null) {
            await this.FETCH('FETCH_UNIT_CONVERSION', '단위환산');
            this.selectRow(-1);
          }
        }
      }
    },
    selectRow(index) {
      this.$store.commit('setSelectedIndexToUnitConversion', index);
      if (index != -1) {
        this.$store.commit(
          'setManagementDataToUnitConversion',
          this.lodash.clonedeep(this.unit_conversions[this.selectedIndex]),
        );
      } else {
        this.$store.commit('setManagementDataToUnitConversion', {
          in_unit_id: this.unit_options[0].value,
          out_unit_id: this.unit_options[0].value,
          cvr_ratio: 1,
        });
      }
    },
    async delete_unit_conversion() {
      const result = await this.DELETE_WITH_PAYLOAD(
        'DELETE_UNIT_CONVERSION',
        this.unit_conversions[this.selectedIndex].id,
        '단위환산식',
      );
      if (result != null) {
        await this.FETCH('FETCH_UNIT_CONVERSION', '단위환산');
        this.selectRow(-1);
      }
    },
  },
  async created() {
    if (this.unit_conversions.length < 1) {
      await this.FETCH('FETCH_UNIT_CONVERSION', '단위환산식');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
  },
};
</script>

<style scoped></style>
